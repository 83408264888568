import { datadogRum } from '@datadog/browser-rum';

export default function initDataDogRUM(pageURL = '') {
  if (pageURL === '') {
    return;
  }

  const currentVersion = APP_VERSION;
  let currentENV = null;

  if (pageURL.search('beta.claims.lu') !== -1) {
    currentENV = 'test';
  } else if (pageURL.search('my.claims.lu') !== -1) {
    currentENV = 'prod';
  } else {
    currentENV = 'dev';
  }

  if (currentENV !== 'dev') {
    datadogRum.init({
      applicationId: '4686a0a0-041d-4b82-b0e3-7d8012c434dd',
      clientToken: 'pub70647dae43703e4508c8ccfc90ff5fa3',
      site: 'datadoghq.com',
      service: 'claims.lu',
      env: currentENV,
      version: currentVersion,
      sampleRate: 100,
      trackInteractions: true,
      beforeSend: (event) => {
        // Filter New Relic Events
        if (
          event &&
          event.resource &&
          event.resource.url &&
          event.resource.url.search('nr-data.net') >= 0
        ) {
          return false;
        }
      },
    });
  }
}
