import 'core-js/stable';
import $ from 'jquery';
import initDataDogRUM from './datadogRUM';
import '../css/raw/main.scss';

// import 'jQuickPicture'; // Maybe need, time will show
// require('./plugins/jquery.jValidForm-1.2.7');
// require('./plugins/jquery-ui.min');

initDataDogRUM(window.location.origin);

$(document).ready(function () {
  if ($('#contentLogin').length > 0) {
    import(/* webpackChunkName: "login" */ './login/login').then((module) => {
      const login = module.default;
      login();
    });
  } else {
    import(/* webpackChunkName: "interface" */ './common/interface').then(function (module) {
      const initInterface = module.default;
      initInterface();

      if ($('#contentUserActivation').length > 0) {
        import(/* webpackChunkName: "users" */ './users/users').then(function (module) {
          const initUsers = module.default;
          initUsers();
        });
      } else {
        if ($('.dash-board').length > 0) {
          import(/* webpackChunkName: "dashboard" */ './dashboard/dashboard').then(function (
            module
          ) {
            const initDashboard = module.default;
            initDashboard();
          });

          if ($('.dash-board-users').length > 0) {
            import(/* webpackChunkName: "users" */ './users/users').then(function (module) {
              const initUsers = module.default;
              initUsers();
            });
          }

          if ($('.dash-board-rules').length > 0) {
            import(/* webpackChunkName: "rules" */ './dashboard/rules').then(function (module) {
              const initRules = module.default;
              initRules();
            });
          }
        } else {
          import(/* webpackChunkName: "creation" */ './claim/common/creation').then(function (
            module
          ) {
            const initClaimNavigation = module.default;
            initClaimNavigation();

            import(/* webpackChunkName: "navigation" */ './claim/common/navigation').then(function (
              module
            ) {
              const initClaimCreation = module.default;
              initClaimCreation();
            });
          });
        }
      }
    });
  }
});

if (process.env.NODE_ENV !== 'production') {
  if (module.hot) {
    module.hot.accept();
  }
}
